import React from 'react';
import { Plane } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { usePlane } from "@react-three/cannon";
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import FenceModel from './FenceModel';

function PhyFloor({ ...props }) {
    const name = (type) => `assets/textures/WoodFloor/WoodFloor_${type}.png`;
    const [
        colorMap,
        displacementMap,
        normalMap,
        roughnessMap,
        aoMap
    ] = useLoader(TextureLoader, [
        name("Color"),
        name("Displacement"),
        name("Normal"),
        name("Roughness"),
        name("AmbientOcclusion")
    ]);
    const [ref] = usePlane(() => ({ ...props }));
    return (
        <Plane args={[100, 100]} ref={ref} receiveShadow>
            <meshStandardMaterial attach="material"
            displacementScale={0.2}
            map={colorMap}
            displacementMap={displacementMap}
            normalMap={normalMap}
            roughnessMap={roughnessMap}
            aoMap={aoMap} />
        </Plane>
    );
}

function createRightFence() {
    return (
        <group>
            <FenceModel position={[50, -9.2, -47]} />
            <FenceModel position={[50, -9.2, -42]} />
            <FenceModel position={[50, -9.2, -37]} />
            <FenceModel position={[50, -9.2, -32]} />
            <FenceModel position={[50, -9.2, -27]} />
            <FenceModel position={[50, -9.2, -22]} />
            <FenceModel position={[50, -9.2, -17]} />
            <FenceModel position={[50, -9.2, -12]} />
            <FenceModel position={[50, -9.2, -7]} />
            <FenceModel position={[50, -9.2, -2]} />
            <FenceModel position={[50, -9.2, 3]} />
            <FenceModel position={[50, -9.2, 8]} />
            <FenceModel position={[50, -9.2, 13]} />
            <FenceModel position={[50, -9.2, 18]} />
            <FenceModel position={[50, -9.2, 23]} />
            <FenceModel position={[50, -9.2, 28]} />
            <FenceModel position={[50, -9.2, 33]} />
            <FenceModel position={[50, -9.2, 38]} />
            <FenceModel position={[50, -9.2, 43]} />
        </group>
    )
}

function createLeftFence() {
    return (
        <group>
            <FenceModel position={[-48, -9.2, -45]} />
            <FenceModel position={[-48, -9.2, -40]} />
            <FenceModel position={[-48, -9.2, -35]} />
            <FenceModel position={[-48, -9.2, -30]} />
            <FenceModel position={[-48, -9.2, -25]} />
            <FenceModel position={[-48, -9.2, -20]} />
            <FenceModel position={[-48, -9.2, -15]} />
            <FenceModel position={[-48, -9.2, -10]} />
            <FenceModel position={[-48, -9.2, -5]} />
            <FenceModel position={[-48, -9.2, 0]} />
            <FenceModel position={[-48, -9.2, 5]} />
            <FenceModel position={[-48, -9.2, 10]} />
            <FenceModel position={[-48, -9.2, 15]} />
            <FenceModel position={[-48, -9.2, 20]} />
            <FenceModel position={[-48, -9.2, 25]} />
            <FenceModel position={[-48, -9.2, 30]} />
            <FenceModel position={[-48, -9.2, 35]} />
            <FenceModel position={[-48, -9.2, 40]} />
            <FenceModel position={[-48, -9.2, 45]} />
        </group>
    )
}

function createFrontFence() {
    return (
        <group>
            <FenceModel position={[-45, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-40, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-35, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-30, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-25, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-20, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-15, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-10, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-5, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[0, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[5, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[10, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[15, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[20, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[25, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[30, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[35, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[40, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[45, -9.2, -48]} rotation={ [0, -Math.PI/2, 0]} />
        </group>
    )
}

function createBackFence() {
    return (
        <group>
            <FenceModel position={[-43, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-38, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-33, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-28, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-23, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-18, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-13, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-8, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[-3, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[2, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[7, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[12, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[17, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[22, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[27, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[32, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[37, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[42, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
            <FenceModel position={[47, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
        </group>
    )
}
export default function TreasuryRoom() {
    return (
        <group>
            <PhyFloor position={[0, -10, 0]} rotation={[-Math.PI / 2, 0, 0]} />
            { createRightFence() }
            { createLeftFence() }
            { createFrontFence() }
            { createBackFence() }
            <FenceModel position={[0, -9.2, 50]} rotation={ [0, -Math.PI/2, 0]} />
        </group>
    );
}