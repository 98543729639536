/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function DispenserModel(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/assets/models/dispenser.glb')
    return (
        <group ref={group} {...props} dispose={null} scale={[50, 50, 50]} position={[0, 10, -25]} rotation={[0, Math.PI/2, 0]}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Box001.geometry}
                material={nodes.Box001.material}
                position={[-0.02, 1.02, -0.02]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Box002.geometry}
                material={nodes.Box002.material}
                position={[-0.48, 1.1, -0.02]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Box003.geometry}
                material={nodes.Box003.material}
                position={[-0.02, 2.47, -0.02]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Box004.geometry}
                material={nodes.Box004.material}
                position={[-0.49, 0.79, 0.01]}
                scale={[0.02, 0.02, 0.02]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Plane001.geometry}
                material={nodes.Plane001.material}
                position={[0.53, 1.02, -0.72]}
                scale={[0.03, 0.03, 0.03]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Box005.geometry}
                material={materials.Glass}
                position={[0.04, 1.3, -0.02]}
                scale={[0.02, 0.09, 0.02]}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Box006.geometry}
                material={materials.Liquid}
                position={[0.03, 1.29, -0.02]}
                scale={[0.02, 0.06, 0.02]}
            />
        </group>
    )
}

useGLTF.preload('/assets/models/dispenser.glb')