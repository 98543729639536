/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { useBox } from '@react-three/cannon'

export default function Fence(props) {
  const [ref] = useBox(() => ({ ...props, type: 'Static', mass: 10, collisionResponse: true, args: [5, 150, 5] }))
  const { nodes, materials } = useGLTF('/assets/models/fence.glb')
  return (
    <group ref={ref} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.FENCE.geometry}
        material={materials.Material}
        position={[-0.67, 0.62, 1.05]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[1.3, 1.3, 1.3]}
      />
    </group>
  )
}

useGLTF.preload('/assets/models/fence.glb')