import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import { Physics } from '@react-three/cannon';
import SecretLayout from '../components/secret-layout/secret-layout';
import SpawnPotions from '../components/treasury/SpawnPotions';
import DispenserModel from '../components/treasury/DispenserModel';
import Room from '../components/treasury/Room';
import Environment from '../components/treasury/Environment';

export default function Treasury() {
  return (
    <SecretLayout
      title="Floating Treasury"
      navIndex={2}>
      <Canvas style={{ height: '650px', width: '100%' }}
        colorManagement
        shadowMap
        camera={{ position: [0, 10, 20], near: 0.1, far: 1000 }}>
        <OrbitControls enableZoom={true} enablePan={true} enableDamping dampingFactor={0.1} rotateSpeed={-0.15} />
        <Suspense fallback={<Html>Loading...</Html>}>
          <Environment />
          <Physics gravity={[0, -10, 0]}>
            <Room />
            <DispenserModel />
            <SpawnPotions />
          </Physics>
          <ambientLight intensity={0.3} />
          <pointLight intensity={0.3} position={[5, 0, 5]} castShadow />
          <directionalLight intensity={0.8} position={[20, -20, 20]} castShadow />
        </Suspense>
      </Canvas>
    </SecretLayout>
  );
};