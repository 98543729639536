import React, { useState, useEffect } from 'react';
import SlpModel from './SlpModel';

const DURATION = 30000;
const POTION_SPAWN_COUNT = 500;

function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
      }

function createPotions() {
    let potions = []
    for (var i = 0; i < POTION_SPAWN_COUNT; i++) {
        var x = getRndInteger(-4, 4);
        var y = 45;
        var z = getRndInteger(-4, 4);
        potions[i] = [x, y, z]
    }
    return potions
}

export default function SpawnPotions() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let counter = count;
        const interval = setInterval(() => {
            if (counter >= createPotions().length) {
                clearInterval(interval);
            } else {
                setCount(count => count + 1);
                counter++; // local variable that this closure will see
            }
        }, DURATION/POTION_SPAWN_COUNT);
        return () => clearInterval(interval);
    }, [createPotions()]);

    const potions = createPotions().slice(0, count).map((coords, i) => {
        return (
            (<SlpModel key={i} position={coords} />)
        )
    })

    return (
        <group>
            {potions}
        </group>
    );
}