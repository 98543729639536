import * as THREE from "three";
import React, { useState, useEffect, useRef } from "react";
import { useThree, useLoader } from "@react-three/fiber";
import caveImg from '../../images/cavePanorama.jpg'

//Click on the big rectangular window

const PanoHotspot = () => {
  const texture = useLoader(THREE.TextureLoader, caveImg);
  const [canvasCreated, setCanvasCreated] = useState(false);
  const { camera } = useThree();
  const self = useRef();

  let canvas2d;
  const raycaster = new THREE.Raycaster();
  let canvas = document.createElement("canvas");
  canvas.width = texture.image.naturalWidth / 12;
  canvas.height = texture.image.naturalHeight / 12;
  canvas.style.display = "none";

  setTimeout(() => {
    canvas2d = canvas.getContext("2d");
    if (canvas2d) {
      canvas2d.drawImage(texture.image, 0, 0, canvas.width, canvas.height);
      setCanvasCreated(true);
    } else {
      console.error("cant create canvas");
    }
  }, 600);

  const checkIfHit = (event) => {
    let mouse = new THREE.Vector2();

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects([self.current]);

    if (intersects.length > 0) {
      if (canvas2d) {
        let x = canvas.width * intersects[0].uv.x;
        let y = canvas.height * (1 - intersects[0].uv.y);
      }
    }
  };

  useEffect(() => {
    if (canvasCreated) {
      document.addEventListener("mousemove", checkIfHit, false);
    }
    return () => {
      document.removeEventListener("mousemove", checkIfHit);
    };
  }, [canvasCreated]);

  return (
    <mesh ref={self} scale={[-1, 1, 1]}>
      <sphereBufferGeometry args={[500, 60, 40]} />
      <meshBasicMaterial
        map={texture}
        side={THREE.BackSide}
        transparent={true}
        opacity={0}
      />
    </mesh>
  );
};

const MainPano = () => {
  const mainTexture = useLoader(THREE.TextureLoader, caveImg)
  return (
    <mesh scale={[-1, 1, 1]}>
      <sphereBufferGeometry args={[500, 60, 40]} />
      <meshBasicMaterial map={mainTexture} side={THREE.BackSide} />
    </mesh>
  );
};

export default function Environment() {
  return (
    <group>
      <MainPano />
      <PanoHotspot />
    </group>
  );
};
