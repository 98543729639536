/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from 'react'
import { useGLTF } from '@react-three/drei'
import { useSphere } from '@react-three/cannon'

export default function SlpModel(props) {
  const [ref, api] = useSphere(() => ({ ...props, mass: 1 }))
  const { nodes } = useGLTF('/assets/models/slp.glb')
  return (
    <group ref={ref} dispose={null} onClick={() => api.applyImpulse([0, 0, -10], [0, 0, 0])} >
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cork.geometry}
        material={nodes.Cork.material}
        position={[0.21, 1.23, 0]}
        scale={[0.31, 0.31, 0.31]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Flask_top.geometry}
        material={nodes.Flask_top.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Flask.geometry}
        material={nodes.Flask.material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Water.geometry}
        material={nodes.Water.material}
        position={[0, -0.26, 0]}
        scale={[0.83, 0.83, 0.83]}
      />
    </group>
  )
}

useGLTF.preload('/assets/models/slp.glb')
